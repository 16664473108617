import {
  alignContent,
  alignItems,
  compose,
  grid,
  justifyContent,
  justifyItems,
  GridProps as ReGridProps,
} from 'styled-system';
import styled from 'styled-components';

import Box, { BoxProps } from 'components/Box';

export type GridProps = BoxProps & ReGridProps;

const Grid = styled(Box)<GridProps>(
  compose(grid, alignItems, alignContent, justifyItems, justifyContent)
);

Grid.defaultProps = {
  display: 'grid',
};

export default Grid;
