import variants from 'components/theme/variants';

const breakpoints: Array<string> & { sm?: string; md?: string; lg?: string; header?: string } = [
  '768px',
  '1000px',
  '1200px',
];
breakpoints.sm = breakpoints[0];
breakpoints.md = breakpoints[1];
breakpoints.lg = breakpoints[2];
breakpoints.header = breakpoints[2];

export const rgba = (colour: string, alpha: number): string => {
  const colourValues = colour.substring(1);
  const red = parseInt(colourValues.substring(0, 2), 16);
  const green = parseInt(colourValues.substring(2, 4), 16);
  const blue = parseInt(colourValues.substring(4, 6), 16);
  return `rgba(${red}, ${green}, ${blue}, ${alpha})`;
};

export const colors = {
  trueBlack: '#000000',
  black: '#121921',
  grey900: '#2C343D',
  grey90090: rgba('#2C343D', 0.9),
  grey700: '#4F5D6D',
  grey500: '#95A0AC',
  grey300: '#CCD3DB',
  grey100: '#EEF2F6',
  white: '#FFFFFF',
  white80: rgba('#FFFFFF', 0.8),
  white70: rgba('#FFFFFF', 0.7),
  darkGold: '#887444',
  gold: '#B1A179',
  lightGold: '#E7D8B1',
};

const fonts = {
  source: "'Source Serif Pro', serif",
  playfair: "'Playfair Display', serif",
  raleway: "'Raleway', sans-serif",
};

const fontSizes = [
  '1.2rem',
  '1.4rem',
  '1.6rem',
  '1.8rem',
  '2rem',
  '2.4rem',
  '3rem',
  '3.6rem',
  '4.8rem',
  '6rem',
];

export const shadows = {
  light: '0px 4px 6px rgba(0, 0, 0, 0.1)',
  popover: '0px 4px 6px rgba(0, 0, 0, 0.2)',
  card: '0 3px 6px rgba(0, 0, 0, 0.15), 0 2px 4px rgba(0, 0, 0, 0.12)',
};

const space = [0, 4, 8, 12, 16, 24, 32, 48, 64, 96, 128, 192, 256, 384, 512, 640, 768];

const theme = {
  breakpoints,
  colors,
  fonts,
  fontSizes,
  shadows,
  space,
  variants,
};

export type ThemeType = typeof theme;
export default theme;
