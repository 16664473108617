// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-business-law-tsx": () => import("./../../../src/pages/business-law.tsx" /* webpackChunkName: "component---src-pages-business-law-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-employment-law-employees-tsx": () => import("./../../../src/pages/employment-law/employees.tsx" /* webpackChunkName: "component---src-pages-employment-law-employees-tsx" */),
  "component---src-pages-employment-law-employers-tsx": () => import("./../../../src/pages/employment-law/employers.tsx" /* webpackChunkName: "component---src-pages-employment-law-employers-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-pay-tsx": () => import("./../../../src/pages/pay.tsx" /* webpackChunkName: "component---src-pages-pay-tsx" */),
  "component---src-pages-practice-areas-tsx": () => import("./../../../src/pages/practice-areas.tsx" /* webpackChunkName: "component---src-pages-practice-areas-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-resources-tsx": () => import("./../../../src/pages/resources.tsx" /* webpackChunkName: "component---src-pages-resources-tsx" */),
  "component---src-pages-terms-of-use-tsx": () => import("./../../../src/pages/terms-of-use.tsx" /* webpackChunkName: "component---src-pages-terms-of-use-tsx" */),
  "component---src-templates-resource-page-tsx": () => import("./../../../src/templates/resource-page.tsx" /* webpackChunkName: "component---src-templates-resource-page-tsx" */)
}

