import React from 'react';

import Box, { BoxProps } from 'components/Box';

export type SpanProps = BoxProps;

const Span: React.FC<SpanProps> = React.forwardRef((props, ref) => (
  <Box as="span" ref={ref} {...props} />
));
Span.displayName = 'Span';

export default Span;
