const common = {
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: 0,
  border: 0,
  height: 40,
  py: 0,
  px: 4,
  sx: {
    cursor: 'pointer',
    appearance: 'none',
    textDecoration: 'none',
    '&[disabled]': { cursor: 'auto' },
  },
};
const commonSx = common.sx;

const buttonTheme = {
  primary: {
    ...common,
    bg: 'gold',
    color: 'white',
    sx: {
      ...commonSx,
      '&:hover': {
        bg: 'darkGold',
      },
    },
  },
  secondary: {
    ...common,
    border: '1px solid',
    borderColor: 'gold',
    color: 'gold',
    sx: {
      ...commonSx,
      '&:hover': {
        color: 'white',
        bg: 'gold',
      },
    },
  },
  tertiary: {
    ...common,
    border: '1px solid',
    borderColor: 'darkGold',
    bg: 'darkGold',
    color: 'white',
    sx: {
      ...commonSx,
      '&:hover': {
        color: 'darkGold',
        bg: 'white',
      },
    },
  },
};
export type ButtonTheme = keyof typeof buttonTheme;
export default buttonTheme;
