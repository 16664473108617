import { Link as GatsbyLink, GatsbyLinkProps } from 'gatsby';
import {
  border,
  color,
  compose,
  flexbox,
  grid,
  layout,
  position,
  space,
  typography,
  variant,
} from 'styled-system';
import { css } from '@styled-system/css';
import React from 'react';
import styled from 'styled-components';

import { BoxProps } from 'components/Box';
import variants from 'components/theme/variants';

const linkSx = (noDecorate: boolean) => ({
  cursor: 'pointer',
  textDecoration: 'none',
  '&:hover': {
    textDecoration: noDecorate ? 'none' : 'underline',
  },
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type LinkProps = GatsbyLinkProps<any> &
  BoxProps & {
    noDecorate?: boolean;
  };

const StyledLink = styled(
  ({
    borderColor: _bc,
    borderRadius: _br,
    alignItems: _ai,
    justifyContent: _jc,
    justifySelf: _js,
    lineHeight: _lh,
    gridColumn: _gc,
    textAlign: _ta,
    ...rest
  }) => <GatsbyLink {...rest} />
)<LinkProps>(
  {
    textDecoration: 'none',
  },
  compose(color, space, typography, layout, position, border, flexbox, grid, variant({ variants })),
  (props) => css(props.sx)(props.theme)
);

const Link: React.FC<LinkProps> = React.forwardRef(
  ({ noDecorate = false, to, sx, ...props }, ref) => {
    return (
      <StyledLink
        innerRef={ref}
        to={to}
        color="inherit"
        sx={{ ...linkSx(noDecorate), ...sx }}
        {...props}
      />
    );
  }
);
Link.displayName = 'Link';

export default Link;
