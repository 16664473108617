import { ThemeProvider } from 'styled-components';
import React from 'react';

import theme from './components/theme';
import GlobalStyles from './components/theme/global-styles';

const wrapPageElement = ({ element }) => (
  <ThemeProvider theme={theme}>
    <GlobalStyles />
    {element}
  </ThemeProvider>
);

export default wrapPageElement;
