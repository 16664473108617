import React from 'react';

import Box, { BoxProps } from 'components/Box';

export type FlexProps = BoxProps;

const Flex: React.FC<FlexProps> = React.forwardRef((props, ref) => (
  <Box ref={ref} display="flex" alignItems="center" {...props} />
));
Flex.displayName = 'Flex';

export default Flex;
