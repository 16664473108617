// Base sets of values
const headerSans = {
  fontFamily: 'raleway',
  fontWeight: '400',
};
const headerSerif = {
  fontFamily: 'playfair',
  fontWeight: '400',
};
const headerSerifBold = {
  fontFamily: 'playfair',
  fontWeight: '700',
};

const header1 = {
  fontSize: 8,
  fontFamily: 'raleway',
  fontWeight: '800',
};
const header1Serif = {
  fontFamily: 'playfair',
  fontWeight: '400',
  fontSize: 8,
  lineHeight: '1em',
  textTransform: 'uppercase',
};

const header2 = {
  ...headerSans,
  fontSize: 7,
};
const header2Serif = {
  ...headerSerif,
  fontSize: 7,
  lineHeight: '1.2em',
};

const header3Serif = {
  ...headerSerif,
  fontSize: 6,
  lineHeight: '1.2em',
};

const header4Serif = {
  ...headerSerif,
  fontSize: 5,
};
const header4Bold = {
  ...headerSerifBold,
  fontSize: 5,
};

const header5 = {
  ...headerSans,
  fontSize: 4,
};
const header5Serif = {
  ...headerSerif,
  fontSize: 4,
};
const header5Bold = {
  ...headerSerifBold,
  fontSize: 4,
};

const header6 = {
  ...headerSans,
  fontSize: 2,
};
const header6Serif = {
  ...headerSerif,
  fontSize: 2,
};
const header6Bold = {
  ...headerSerifBold,
  fontSize: 2,
};

const italic = {
  fontFamily: 'playfair',
  fontWeight: '300',
  fontSize: 4,
  lineHeight: '1.6em',
  fontStyle: 'italic',
};

const body1 = {
  fontFamily: 'source',
  fontWeight: '400',
  fontSize: 2,
  lineHeight: '1.5em',
};

const body2 = {
  fontFamily: 'source',
  fontWeight: '400',
  fontSize: 1,
  lineHeight: '1.4em',
};

const block = {
  fontFamily: 'raleway',
  fontWeight: '600',
  fontSize: '1.4rem',
  textTransform: 'uppercase',
  letterSpacing: '0.05em',
};

export default {
  header1,
  header1Serif,
  header2,
  header2Serif,
  header3Serif,
  header4Serif,
  header4Bold,
  header5,
  header5Serif,
  header5Bold,
  header6,
  header6Serif,
  header6Bold,
  italic,
  body1,
  body2,
  block,
};
