import React, { useCallback, useContext, useState } from 'react';

import Box from 'components/Box';
import Button from 'components/Button';
import Flex from 'components/Flex';
import Grid from 'components/Grid';
import Input, { TextArea } from 'components/Input';
import Link from 'components/Link';
import Span from 'components/Span';

const encode = (data: Record<string, string | boolean>): string => {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&');
};

type FormValues = {
  botField: string;
  name: string;
  email: string;
  phone: string;
  subject: string;
  description: string;
  contactMethod: 'Phone' | 'Email' | 'NoPreference';
  acknowledgedPolicies: boolean;
};
const initialFormValues: FormValues = {
  botField: '',
  name: '',
  email: '',
  phone: '',
  subject: '',
  description: '',
  contactMethod: 'Email',
  acknowledgedPolicies: false,
};
export const ContactFormContext = React.createContext<{
  values: FormValues;
  setValues: React.Dispatch<React.SetStateAction<FormValues>>;
  // eslint-disable-next-line @typescript-eslint/no-empty-function
}>({ values: initialFormValues, setValues: () => {} });

export const ContactFormProvider: React.FC = ({ children }) => {
  const [values, setValues] = useState<FormValues>(initialFormValues);
  return (
    <ContactFormContext.Provider value={{ values, setValues }}>
      {children}
    </ContactFormContext.Provider>
  );
};

const ContactForm: React.FC = () => {
  const { values, setValues } = useContext(ContactFormContext);
  const [error, setError] = useState('');

  const handleFormSubmit = useCallback(
    (e: React.FormEvent) => {
      e.preventDefault();
      console.log(values);
      if (values.botField) {
        setError('Sorry bot, not this time!');
        return;
      } else if (!values.name) {
        setError('Please provide a name so that we know how to address you.');
        return;
      } else if (!values.email && !values.phone) {
        setError('You must enter either a phone number or email address.');
        return;
      } else if (!values.subject) {
        setError('You must enter a subject for your message.');
        return;
      } else if (!values.description) {
        setError('Please provide more information about your request in the description box.');
        return;
      } else if (!values.acknowledgedPolicies) {
        setError('You must click the checkbox to accept the site policies before submitting.');
        return;
      }
      fetch('/', {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: encode({ 'form-name': 'contact', ...values }),
      })
        .then(() => {
          alert('Form submission successful! We will reach out to you as soon as we can.');
          setValues(initialFormValues);
          setError('');
        })
        .catch((e) => {
          console.warn('ERROR SUBMITTING FORM', e);
          setError('An unexpected error occurred while submitting your form!');
        });
    },
    [values, setError, setValues]
  );

  const handleInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const target = e.target;
      const value =
        target instanceof HTMLInputElement && target.type === 'checkbox'
          ? target.checked
          : target.value;
      const name = target.name;
      console.log(name, value);
      setValues((v) => ({ ...v, [name]: value }));
    },
    [setValues]
  );

  return (
    <Box
      as="form"
      name="contact"
      data-netlify="true"
      netlify-honeypot="botField"
      onSubmit={handleFormSubmit}
    >
      <Box position="absolute" zIndex={-99999} opacity="0" left={-9999}>
        <label>
          If you are a human, do not fill this out:{' '}
          <input name="botField" value={values.botField} onChange={handleInputChange} />
        </label>
      </Box>
      <Grid
        alignItems="center"
        gridTemplateColumns="max-content 1fr"
        gridColumnGap={3}
        gridRowGap={5}
      >
        <Box as="label" htmlFor="name">
          Full Name:
        </Box>
        <Input
          id="name"
          name="name"
          placeholder="eg. Jane Doe"
          value={values.name}
          onChange={handleInputChange}
        />
        <Box as="label" htmlFor="email">
          Email:
        </Box>
        <Input
          id="email"
          name="email"
          placeholder="eg. name@company.com"
          value={values.email}
          onChange={handleInputChange}
        />
        <Box as="label" htmlFor="phone">
          Phone:
        </Box>
        <Input
          id="phone"
          name="phone"
          placeholder="eg. 123-456-7890"
          value={values.phone}
          onChange={handleInputChange}
        />
        <Box as="label" htmlFor="subject">
          Subject:
        </Box>
        <Input id="subject" name="subject" value={values.subject} onChange={handleInputChange} />
      </Grid>
      <Box as="label" display="block" htmlFor="description" mt={5}>
        Brief description of your matter:
      </Box>
      <TextArea
        id="description"
        name="description"
        mt={2}
        value={values.description}
        onChange={handleInputChange}
      />
      <Box mt={4}>Preferred Method of Contact:</Box>
      <Flex justifyContent="space-between">
        <Box as="label">
          <input
            type="radio"
            value="Email"
            name="contactMethod"
            checked={values.contactMethod === 'Email'}
            onChange={handleInputChange}
          />
          <Span ml={2}>Email</Span>
        </Box>
        <Box as="label" ml={3}>
          <input
            type="radio"
            value="Phone"
            name="contactMethod"
            checked={values.contactMethod === 'Phone'}
            onChange={handleInputChange}
          />
          <Span pl={2}>Phone Call</Span>
        </Box>
        <Box as="label" ml={3}>
          <input
            type="radio"
            value="NoPreference"
            name="contactMethod"
            checked={values.contactMethod === 'NoPreference'}
            onChange={handleInputChange}
          />
          <Span pl={2}>No Preference</Span>
        </Box>
      </Flex>
      <Box as="label" display="block" sx={{ cursor: 'pointer' }} mt={4}>
        <input
          type="checkbox"
          name="acknowledgedPolicies"
          checked={values.acknowledgedPolicies}
          onChange={handleInputChange}
          style={{ cursor: 'pointer' }}
        />{' '}
        I have read the <Link to="/privacy-policy/">Privacy Policy</Link> and{' '}
        <Link to="/terms-of-use/">Terms of Use</Link>.
      </Box>
      {error && (
        <Box color="red" mt={4}>
          {error}
        </Box>
      )}
      <Button type="submit" bVariant="primary" mt={4} width="100%">
        Submit
      </Button>
    </Box>
  );
};

export default ContactForm;
