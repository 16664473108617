import {
  BorderProps,
  FlexboxProps,
  TypographyProps,
  PositionProps,
  border,
  compose,
  flexbox,
  position,
} from 'styled-system';
import { Box, BoxProps } from 'rebass/styled-components';
import React from 'react';
import styled from 'styled-components';

import buttonTheme, { ButtonTheme } from 'components/theme/button-theme';

export type ButtonProps = Omit<BoxProps, 'onClick'> &
  BorderProps &
  FlexboxProps &
  TypographyProps &
  PositionProps &
  JSX.IntrinsicElements['button'] & {
    bVariant?: ButtonTheme;
    variant?: string;
  };

const ButtonBox = styled(Box)<ButtonProps>(compose(flexbox, border, position));

const Button: React.FC<ButtonProps> = React.forwardRef(
  ({ bVariant = 'primary', sx, children, ...props }, ref) => {
    const { sx: buttonVariantSx = {}, ...buttonVariant } = buttonTheme[bVariant] || {};
    return (
      <ButtonBox
        ref={ref}
        as="button"
        type="button"
        {...buttonVariant}
        sx={{
          ...buttonVariantSx,
          ...sx,
        }}
        {...props}
      >
        {children}
      </ButtonBox>
    );
  }
);

export default Button;
