import {
  BorderProps,
  FlexboxProps,
  PositionProps,
  TypographyProps,
  border,
  position,
  gridColumn,
  gridRow,
  gridArea,
  GridColumnProps,
  GridRowProps,
  GridAreaProps,
} from 'styled-system';
import { Box as ReBox, BoxProps as ReBoxProps } from 'rebass/styled-components';
import styled from 'styled-components';

export type BoxProps = ReBoxProps &
  FlexboxProps &
  TypographyProps &
  PositionProps &
  BorderProps &
  GridColumnProps &
  GridRowProps &
  GridAreaProps;

const Box = styled(ReBox)<BoxProps>`
  ${position}
  ${border}
  ${gridColumn}
  ${gridRow}
  ${gridArea}
`;

export default Box;
