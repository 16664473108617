import React from 'react';

import Box, { BoxProps } from 'components/Box';

type Props = Omit<BoxProps, 'onChange'> & JSX.IntrinsicElements['input'];

const Input: React.FC<Props> = (props) => {
  return (
    <Box
      as="input"
      width="100%"
      height={36}
      px={3}
      border="2px solid"
      borderColor="grey700"
      sx={{ transition: 'all 200ms ease', outline: 'none', '&:focus': { borderColor: 'gold' } }}
      {...props}
    />
  );
};

type TextAreaProps = Omit<BoxProps, 'onChange'> & JSX.IntrinsicElements['textarea'];
export const TextArea: React.FC<TextAreaProps> = (props) => {
  return (
    <Box
      as="textarea"
      width="100%"
      px={3}
      py={2}
      rows={4}
      border="2px solid"
      borderColor="grey700"
      sx={{ transition: 'all 200ms ease', outline: 'none', '&:focus': { borderColor: 'gold' } }}
      {...props}
    />
  );
};

export default Input;
