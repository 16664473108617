import { createGlobalStyle } from 'styled-components';

import { ThemeType } from './';
import modernNormalize from './modern-normalize';

const GlobalStyles = createGlobalStyle<{ theme: ThemeType }>`
  ${modernNormalize}

  html {
    font-size: 62.5%;
    height: 100%;
  }

  body {
    height: 100%;
    margin: 0;
    font-family: 'Source Serif Pro', serif;
    font-size: 1.4rem;
    font-weight: 400;
    color: ${(props) => props.theme.colors.black};
    background-color: ${(props) => props.theme.colors.white};
  }

  a {
    color: inherit;
  }

  blockquote,
  q {
    quotes: none;
  }

  blockquote:before,
  blockquote:after,
  q:before,
  q:after {
    content: none;
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
    -webkit-margin-before: 0;
    -webkit-margin-after: 0;
    -webkit-margin-start: 0;
    -webkit-margin-end: 0;
  }
  h1,h2,h3,h4,h5,h6 {
    font-family: 'Playfair Display', serif;
  }

  ul {
    list-style-type: none;
    li {
      position: relative;
      &::before {
        content: "";
        height: 0.5em;
        width: 0.5em;
        background-color: ${(props) => props.theme.colors.gold};
        position: absolute;
        left: -1.5em;
        top: 0.5em;
      }
    }
  }

  body:not(.user-is-tabbing) *:focus {
    outline: none;
  } 
`;

export default GlobalStyles;
